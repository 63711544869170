<template>
    <el-scrollbar class="seller-scrollbar">
		<el-card class="seller-card">
			<div slot="header" class="title">{{ $t('seller.customer.createCrowd') }}</div>
			<div class="page-wrap">
				<div class="page-picker">
					<div class="picker-top"><span>{{ $t('seller.customer.crowdText4') }}</span></div>
					<div class="picker-tips">{{ $t('seller.customer.crowdText5') }}</div>
					<div class="picker-editor">
						<div class="editor-title">{{ $t('seller.customer.crowdText6') }}</div>
						<div class="editor-list">
							<template v-for="(item,index) in checkList">
								<div class="item" :key="item.sign">
									<div class="item-name">{{item.name}}</div>
									<div class="item-value" v-if="item.sign === 'sex' && item.value !== ''">
										<el-tag v-for="tag in item.value.split(',')" :key="tag">{{ tag | typeFilter($t('seller.filters.crowdSex'))}}</el-tag>
									</div>
									<div class="item-value" v-else-if="item.sign === 'region' && item.value !== ''">
										<el-tag>{{ item.value.split(',').length }}{{$t('seller.unit.pcs')}}</el-tag>
									</div>
									<div class="item-value" v-else-if="['buy','no_buy','collect','no_collect','add_cart','no_add_cart','visit','no_visit','first_order'].includes(item.sign) && item.value !== ''">
										<el-tag>{{ item.value }}{{$t('seller.unit.day')}}</el-tag>
									</div>
									<div class="item-value" v-else-if="['cart_goods','buy_goods'].includes(item.sign) && item.value !== ''">
										<el-tag>{{ item.value.split(',').length }}{{ $t('seller.customer.crowdText7') }}</el-tag>
									</div>
									<div class="item-value" v-else-if="['last_trade_time','first_order_time','last_pay_time'].includes(item.sign) && item.min !== '' && item.max !== ''">
										<el-tag>{{ item.min | parseTime('{y}{m}{d}') }}</el-tag>
										<el-tag>{{ item.max | parseTime('{y}{m}{d}') }}</el-tag>
									</div>
									<div class="item-value" v-else-if="['pay_num','per_ticket_sales','trade_amount','trade_num','repo_cycle','pay_amount'].includes(item.sign) && item.min !== '' && item.max !== ''">
										<el-tag v-if="item.type === 1">{{$t('seller.unit.lessThan')}}{{ item.max }}{{ ['pay_num','trade_num'].includes(item.sign)?1:['per_ticket_sales','trade_amount','pay_amount'].includes(item.sign)?2:3 | typeFilter($t('seller.filters.unit'))}}</el-tag>
										<el-tag v-else-if="item.type === 2">{{$t('seller.unit.greaterThan')}}{{ item.min }}{{ ['pay_num','trade_num'].includes(item.sign)?1:['per_ticket_sales','trade_amount','pay_amount'].includes(item.sign)?2:3 | typeFilter($t('seller.filters.unit'))}}</el-tag>
										<el-tag v-else>{{ item.min }}{{$t('seller.unit.to')}}{{ item.max }}{{ ['pay_num','trade_num'].includes(item.sign)?1:['per_ticket_sales','trade_amount','pay_amount'].includes(item.sign)?2:3 | typeFilter($t('seller.filters.unit'))}}</el-tag>
									</div>
									<div class="item-tips" v-else>{{ $t('seller.customer.crowdText8') }}</div>
									<div class="item-icon el-icon-edit" @click="handleEditShow(item,index)"></div>
									<div class="item-icon el-icon-delete" @click="handleDeteleTag(item,index)"></div>
								</div>
								<div class="item and" :key="index" v-if="index < checkList.length - 1">
									<div class="and-icon">且</div>
								</div>
							</template>
						</div>
						<el-empty v-if="!checkList.length > 0" :description="$t('seller.customer.crowdText9')"></el-empty>
					</div>
					<el-dialog :title="tagsForm.name" :visible.sync="dialogVisibleTags" width="600px" custom-class="seller-dialog">
						<div class="dialog-crowd">
							<div class="crowd-tips">{{ tagsForm.sign | typeFilter($t('seller.filters.crowdTagsDesc')) }}</div>
							<div class="crowd-wrap" v-if="tagsForm.sign === 'sex'">
								<el-checkbox-group v-model="tagsForm.value">
									<el-checkbox v-for="item in $t('seller.options.crowdSex')" :key="item.value" :label="item.value">{{ item.label }}</el-checkbox>
								</el-checkbox-group>
							</div>
							<div class="crowd-wrap" v-if="tagsForm.sign === 'region'">
								<div class="region-list">
									<div class="checkbox-row" v-for="number in Math.ceil(regionList.length / 3)" :key="number">
										<span class="checkbox-item" v-for="item in regionList.slice((number -1)*3,number * 3)" :key="item.region_id">
											<el-checkbox v-model="checkProvince" :indeterminate="indeterminate.includes(item.region_id)" :label="item.region_id" @change="(val)=> { handleChangeProvince(val,item,number)}">{{item.region_name}}</el-checkbox>
											<i v-if="provinceData && provinceData.region_id === item.region_id" class="el-icon-arrow-up" @click="handleShowProvince"></i>
											<i v-else class="el-icon-arrow-down" @click="handleOpenProvince(item,number)"></i>
										</span>
										<div class="nested-checkbox-group" v-if="number === visibleNumber && provinceData">
											<el-checkbox-group v-model="tagsForm.value" @change="handleChangeCity">
												<el-checkbox v-for="item in provinceData.children" :key="item.region_id" :label="item.region_id">{{item.region_name}}</el-checkbox>
											</el-checkbox-group>
										</div>
									</div>
								</div>
							</div>
							<div class="crowd-wrap" v-else-if="['last_trade_time','first_order_time','last_pay_time'].includes(tagsForm.sign)">
								<el-date-picker
									v-model="tagsTimes"
									type="daterange"
									:range-separator="$t('seller.unit.to')"
									size="medium"
									:start-placeholder="$t('seller.placeholder.startDate')"
									:end-placeholder="$t('seller.placeholder.endDate')">
								</el-date-picker>
							</div>
							<div class="crowd-wrap" v-else-if="['buy','no_buy','collect','no_collect','add_cart','no_add_cart','visit','no_visit','first_order'].includes(tagsForm.sign)">
								<div class="crowd-item">
									<el-input-number v-model="tagsForm.value" :min="1" :max="['visit','no_visit'].includes(tagsForm.sign)?30:['collect','no_collect','add_cart','no_add_cart'].includes(tagsForm.sign)?180:720" size="medium"></el-input-number>
									<span class="unit">{{ $t('seller.unit.day') }}</span>
								</div>
							</div>
							<div class="crowd-wrap" v-else-if="['cart_goods','buy_goods'].includes(tagsForm.sign)">
								<div class="crowd-goods">
									<div class="item" v-for="goods,index in tagsItems" :key="goods.goods_id">
										<div class="item-pic"><img :src="goods.default_image" alt=""></div>
										<div class="item-main">
											<div class="name">{{ goods.goods_name }}</div>
											<div class="text">
												<div class="price">￥{{ goods.price }}</div>
												<div class="action">
													<a :href="$itemBasePath+'/item/'+goods.goods_id" target="_blank"><el-button type="text">{{ $t('seller.actions.viewDetails') }}</el-button></a>
													<el-button type="text" @click="handleRemoveGoods(goods.goods_id,index)">{{ $t('seller.actions.delete') }}</el-button>
												</div>
											</div>
										</div>
									</div>
									<div class="item upload-item" @click="handleShowGoods">
										<i class="el-icon-plus"></i>
										<p>{{ $t('seller.actions.selectGoods') }}</p>
									</div>
								</div>
								<el-dialog
									title="选择商品"
									:visible.sync="dialogVisibleGoods"
									width="1000px"
									append-to-body
									custom-class="seller-dialog">
									<div class="dialog-crowd-goods">
										<div class="goods-wrap">
											<el-scrollbar class="goods-sidebar">
												<ul>
													<li><span :class="{ active: goodsQuery.goods_cate_id === ''}" @click="handleChangeCate()">未分类</span></li>
													<li v-for="cate in shopCate" :key="cate.cate_id"><span @click="handleChangeCate(cate.cate_id)" :class="{ active: goodsQuery.goods_cate_id === cate.cate_id}">{{ cate.cate_name }}</span>
														<ul v-if="cate.children && cate.children.length > 0">
															<li v-for="child in cate.children" :key="child.cate_id"><span @click="handleChangeCate(child.cate_id)" :class="{ active: goodsQuery.goods_cate_id === child.cate_id}">{{ child.cate_name }}</span></li>
														</ul>
													</li>
												</ul>
											</el-scrollbar>
											<div class="goods-content">
												<div class="goods-filters">
													<el-input :placeholder="$t('seller.placeholder.babyKeyword')" v-model="goodsQuery.goods_name">
														<el-button slot="append" icon="el-icon-search" @click="handleSearchGoods"></el-button>
													</el-input>
												</div>
												<el-scrollbar class="goods-list">
													<el-checkbox-group v-model="dialogCheckGoods">
														<el-checkbox v-for="goods in goodsList" :label="JSON.stringify(goods)" :key="goods.goods_id">
															<div class="check-goods-pic">
																<img :src="goods.default_image" alt="">
																<i class="el-icon-check"></i>
															</div>
															<div class="check-goods-main">
																<div class="name">{{ goods.goods_name }}</div>
																<div class="price">{{ goods.price }}</div>
															</div>
														</el-checkbox>
													</el-checkbox-group>
												</el-scrollbar>
											</div>
										</div>
										<div class="goods-bottom" v-if="dialogCheckGoods.length > 0">
											<ul class="items">
												<li v-for="ckeck,index in dialogCheckGoods" :key="index">
													<img :src="JSON.parse(ckeck).default_image" alt="">
													<i class="el-icon-error" @click="handleRemoveCheck(index)"></i>
												</li>
											</ul>
											<div class="tips">{{ $t('seller.customer.crowdText16') }}{{ dialogCheckGoods.length }}/999）</div>
										</div>
									</div>
									<div slot="footer" class="dialog-footer">
										<el-button @click="dialogVisibleGoods = false">{{$t('seller.actions.cancel')}}</el-button>
										<el-button type="primary" @click="handleConfirmGoods">{{$t('seller.actions.confirm')}}</el-button>
									</div>
								</el-dialog>
							</div>
							<div class="crowd-wrap" v-else-if="['pay_num','per_ticket_sales','trade_amount','trade_num','repo_cycle','pay_amount'].includes(tagsForm.sign)">
								<div class="crowd-item">
									<el-radio-group v-model="tagsForm.type">
										<el-radio v-for="item in $t('seller.options.crowdRange')" :key="item.value" :label="item.value">{{ item.label }}</el-radio>
									</el-radio-group>
								</div>
								<div class="crowd-item" v-if="tagsForm.type === 1">
									<el-input-number v-model="tagsForm.max" :min="1" :max="tagsForm.sign==='repo_cycle'?1000:['pay_num','trade_num'].includes(tagsForm.sign)?10000:100000000" size="medium"></el-input-number>
									<span class="unit">{{ ['pay_num','trade_num'].includes(tagsForm.sign)?1:['per_ticket_sales','trade_amount','pay_amount'].includes(tagsForm.sign)?2:3 | typeFilter($t('seller.filters.unit'))}}</span>
								</div>
								<div class="crowd-item" v-else-if="tagsForm.type === 2">
									<el-input-number v-model="tagsForm.min" :min="1" :max="tagsForm.sign==='repo_cycle'?1000:['pay_num','trade_num'].includes(tagsForm.sign)?10000:100000000" size="medium"></el-input-number>
									<span class="unit">{{ ['pay_num','trade_num'].includes(tagsForm.sign)?1:['per_ticket_sales','trade_amount','pay_amount'].includes(tagsForm.sign)?2:3 | typeFilter($t('seller.filters.unit'))}}</span>
								</div>
								<div class="crowd-item" v-else>
									<el-input-number v-model="tagsForm.min" :min="1" :max="tagsForm.sign==='repo_cycle'?1000:['pay_num','trade_num'].includes(tagsForm.sign)?10000:100000000" size="medium"></el-input-number>
									<span class="split">至</span>
									<el-input-number v-model="tagsForm.max" :min="1" :max="tagsForm.sign==='repo_cycle'?1000:['pay_num','trade_num'].includes(tagsForm.sign)?10000:100000000" size="medium"></el-input-number>
									<span class="unit">{{ ['pay_num','trade_num'].includes(tagsForm.sign)?1:['per_ticket_sales','trade_amount','pay_amount'].includes(tagsForm.sign)?2:3 | typeFilter($t('seller.filters.unit'))}}</span>
								</div>
							</div>
						</div>
						<div slot="footer" class="dialog-footer">
							<el-button type="primary" @click="handleConfirmTags" round size="medium">{{$t('seller.actions.confirm')}}</el-button>
							<el-button @click="dialogVisibleTags = false" round size="medium">{{$t('seller.actions.cancel')}}</el-button>
						</div>
					</el-dialog>
				</div>
				<div class="page-tags">
					<div class="tags-title">{{ $t('seller.customer.crowdText10') }}</div>
					<ul class="tags-search">
						<li><el-input v-model="keyword" :placeholder="$t('seller.placeholder.searchLabel')" size="small" clearable @clear="handleSeach"></el-input></li>
						<li>
							<el-button type="primary" size="small" round @click="handleSeach">{{ $t('seller.actions.search') }}</el-button>
						</li>
					</ul>
					<div class="tags-list" v-if="searchFlag">
						<div class="tag-group">
							<div class="group-search">{{ $t('seller.customer.crowdText11') }}{{ searchList.length }}{{ $t('seller.customer.crowdText12') }}</div>
							<div class="group-tags">
								<div class="tag-item" v-for="item in searchList" :key="item.name" @click="handleCheckTags(item)">
									<div class="item-name">{{ item.name }}</div>
									<div class="item-check el-icon-success" v-if="checkTags.includes(item.sign)"></div>
								</div>
							</div>
						</div>
					</div>
					<template v-else>
						<div class="tags-cate">
							<el-radio-group v-model="tagsActive" size="small" @change="getTagsList">
								<el-radio-button label="base">{{ $t('seller.formItem.shopInfo') }} 2</el-radio-button>
								<el-radio-button label="shop">{{ $t('seller.formItem.shopRelation') }} 20</el-radio-button>
							</el-radio-group>
						</div>
						<div class="tags-list">
							<div class="tag-group" v-for="item in tagsList" :key="item.name">
								<div class="group-title">
									<i v-if="item.icon.indexOf('el-icon') > -1" :class="item.icon"></i>
									<svg-icon v-else :icon-class="item.icon"></svg-icon>
									<span>{{ item.name }}</span>
								</div>
								<div class="group-tags">
									<div class="tag-item" v-for="child in item.children" :key="child.name" @click="handleCheckTags(child)">
										<div class="item-name">{{ child.name }}</div>
										<div class="item-check el-icon-success" v-if="checkTags.includes(child.sign)"></div>
									</div>
								</div>
							</div>
						</div>
					</template>
				</div>
			</div>
			<div class="page-bottom">
				<div style="width: 300px;">
					<el-input v-model="pageInfo.title" :placeholder="$t('seller.placeholder.crowdName')" maxlength="25" size="medium"></el-input>
				</div>
				<div>
					<!-- <el-button round size="medium">计算人群数量</el-button> -->
					<el-button type="primary" round size="medium" @click="handleSave" v-loading="saveLoading">{{ $t('seller.actions.saveCrowd') }}</el-button>
					<el-button round size="medium" @click="$router.push({name: 'customerDashboard'})">{{$t('seller.actions.cancel')}}</el-button>
				</div>
			</div>
		</el-card>
	</el-scrollbar>
</template>

<script>
import { crowdRegion, fetchCrowdInfo, fetchCrowdGoods, createCrowd, updateCrowd  } from "@/api/seller/customer"
import { categoryShop  } from "@/api/seller/category"

const crowdData = [
	{
		name: '人口属性',
		sign: 'base',
		icon: 'el-icon-document',
		children: [
			{ name: '预测性别', sign: 'sex'}
		]
	}, {
		name: '地域特征',
		sign: 'base',
		icon: 'el-icon-map-location',
		children: [
			{ name: '预测地域', sign: 'region'}
		]
	}, {
		name: '店铺行为',
		sign: 'shop',
		icon: 'shop',
		children: [
			{ name: '店铺有购买', sign: 'buy'},
			{ name: '加购物车商品', sign: 'cart_goods'},
			{ name: '店铺无购买', sign: 'no_buy'},
			{ name: '店铺有收藏', sign: 'collect'},
			{ name: '店铺无加购', sign: 'no_add_cart'},
			{ name: '店铺有访问', sign: 'visit'},
			{ name: '店铺有加购', sign: 'add_cart'},
			{ name: '店铺无收藏', sign: 'no_collect'},
			{ name: '店铺无访问', sign: 'no_visit'}
		]
	}, {
		name: '交易行为',
		sign: 'shop',
		icon: 'pay_behavior',
		children: [
			{ name: '购买商品', sign: 'buy_goods'},
			{ name: '最近成功交易时间', sign: 'last_trade_time'},
			{ name: '首次下单时间', sign: 'first_order_time'},
			{ name: '付款次数', sign: 'pay_num'},
			{ name: '客单价', sign: 'per_ticket_sales'},
			{ name: '成功付款金额', sign: 'trade_amount'},
			{ name: '成功交易次数', sign: 'trade_num'},
			{ name: '客户平均回购周期', sign: 'repo_cycle'},
			{ name: '最近付款时间', sign: 'last_pay_time'},
			{ name: '付款金额', sign: 'pay_amount'},
			{ name: '店铺首次下单', sign: 'first_order'}
		]
	}
]
export default {
	data() {
		return {
			pageInfo: {
				crowd_id: 0,
				title: '',
				content: ''
			},
			tagsActive: 'base',
			tagsList: [],
			regionList: [],
			checkTags: [],
			checkList: [],
			tagsForm: {},
			tagsIndex: 0,
			tagsTimes: '',
			keyword: '',
			dialogVisibleTags: false,
			checkProvince: [],
			visibleNumber: 0,
			indeterminate: [],
			provinceData: null,
			searchFlag: false,
			searchList: [],
			saveLoading: false,
			tagsItems: [],
			dialogVisibleGoods: false,
			shopCate: [],
			goodsList: [],
			goodsQuery: {
				goods_cate_id: '',
				goods_name: ''
			},
			dialogCheckGoods: []
		}
	},
	created () {
		this.getData()
		this.getTagsList()
	},
	methods: {
		async getData() {
			const region = await crowdRegion()
			this.regionList = region.data.list
			const category = await categoryShop()
			this.shopCate = category.data.list
			if(this.$route.params.id > 0) {
				const { data } = await fetchCrowdInfo({crowd_id: this.$route.params.id})
				this.pageInfo = data
				this.checkList = data.content?JSON.parse(data.content):[]
				var checkVal = []
				this.checkList.forEach(item => {
					checkVal.push(item.sign)
				})
				this.checkTags = checkVal
			}
			this.getGoodsList()
		},
		getGoodsList(ids) {
			var params = {}
			if(ids) {
				params.goods_ids = ids
			} else {
				params = this.goodsQuery
			}
			fetchCrowdGoods(params).then(response => {
				const { data } = response
				if(ids) {
					this.tagsItems = data.list
				} else {
					this.goodsList = data.list
				}
			})
		},
		handleChangeCate(id='') {
			this.goodsQuery.goods_cate_id = id
			this.getGoodsList()
		},
		handleSearchGoods() {
			this.getGoodsList()
		},
		getTagsList() {
			const list = []
			crowdData.forEach(item => {
				if(item.sign === this.tagsActive) {
					list.push(item)
				}
			})
			this.tagsList = list
		},
		handleSeach() {
			var searchList = []
			if(this.keyword) {
				crowdData.forEach(item => {
					item.children.forEach(child => {
						if(child.name.indexOf(this.keyword)>-1) {
							searchList.push(child)
						}
					})
				})
				this.searchFlag = true
			} else {
				this.searchFlag = false
			}
			this.searchList = searchList
		},
		handleCheckTags(item) {
			if(!this.checkTags.includes(item.sign)) {
				this.checkTags.push(item.sign)
				var row = { ...item }
				if(['last_trade_time','first_order_time','last_pay_time'].includes(item.sign)) {
					row.min = ''
					row.max = ''
				} else if(['pay_num','per_ticket_sales','trade_amount','trade_num','repo_cycle','pay_amount'].includes(item.sign)) {
					row.type = 0
					row.min = ''
					row.max = ''
				} else {
					row.value = ''
				}
				this.checkList.push(row)
			}
		},
		handleEditShow(item,index) {
			this.tagsForm = Object.assign({},item)
			this.tagsIndex= index
			if(['last_trade_time','first_order_time','last_pay_time'].includes(item.sign)) {
				if(item.min && item.max) {
					this.tagsTimes = [item.min*1000,item.max*1000]
				} else {
					this.tagsTimes = ''
				}
			} else if(['pay_num','per_ticket_sales','trade_amount','trade_num','repo_cycle','pay_amount'].includes(item.sign)) {
				this.tagsForm.min = this.tagsForm.min || 1
				var maxVal = 100000000
				if(item.sign === 'repo_cycle') {
					maxVal = 1000
				} else if(['pay_num','trade_num'].includes(item.sign)) {
					maxVal = 10000
				}
				this.tagsForm.max = this.tagsForm.max || maxVal
			} else if(['sex','region'].includes(item.sign)){
				if(this.tagsForm.value) {
					var check_ids = this.tagsForm.value.split(',')
					this.tagsForm.value = check_ids.map(function(id){
						return +id;
					});
					if(item.sign === 'region') {
						this.regionList.forEach(item => {
							var region_ids = []
							item.children.forEach(child => {
								if(this.tagsForm.value.includes(child.region_id)) {
									region_ids.push(item.region_id)
								}
							})
							if(region_ids.length === item.children.length) {
								this.checkProvince.push(item.region_id)
							} else if(region_ids.length > 0 && region_ids.length !== item.children.length) {
								this.indeterminate.push(item.region_id)
							}
						})
					}
				} else {
					this.tagsForm.value = []
				}
			} else if(['cart_goods','buy_goods'].includes(item.sign)) {
				this.tagsItems = []
				if(this.tagsForm.value) {
					this.getGoodsList(this.tagsForm.value)
				}
			}
			this.dialogVisibleTags = true
		},
		handleChangeProvince(val,region,number) {
			region.children.forEach(item => {
				if(this.tagsForm.value.includes(item.region_id)) {
					if(!val) {
						this.tagsForm.value.splice(this.tagsForm.value.indexOf(item.region_id),1)
					}
				} else {
					if(val) {
						this.tagsForm.value.push(item.region_id)
					}
				}
			})
			this.visibleNumber = number
			this.provinceData = region
		},
		handleOpenProvince(region,number) {
			this.visibleNumber = number
			this.provinceData = region
		},
		handleShowProvince() {
			this.visibleNumber = 0
			this.provinceData = null
		},
		handleChangeCity(val) {
			var check = []
			this.provinceData.children.forEach(item => {
				if(this.tagsForm.value.includes(item.region_id)) {
					check.push(item)
				}
			})
			if(check.length > 0) {
				if(check.length !== this.provinceData.children.length && !this.indeterminate.includes(this.provinceData.region_id)) {
					this.indeterminate.push(this.provinceData.region_id)
				} else if(check.length === this.provinceData.children.length && this.indeterminate.includes(this.provinceData.region_id)) {
					this.indeterminate.splice(this.indeterminate.indexOf(this.provinceData.region_id),1)
				}
				if(!this.checkProvince.includes(this.provinceData.region_id)) {
					this.checkProvince.push(this.provinceData.region_id)
				}
			} else {
				if(this.checkProvince.includes(this.provinceData.region_id)) {
					this.checkProvince.splice(this.checkProvince.indexOf(this.provinceData.region_id),1)
				}
				if(this.indeterminate.includes(this.provinceData.region_id)) {
					this.indeterminate.splice(this.indeterminate.indexOf(this.provinceData.region_id),1)
				}
			}
		},
		handleConfirmTags() {
			var params = {...this.tagsForm}
			if(['sex','region'].includes(params.sign)) {
				params.value = params.value.join(',')
			} else if(['last_trade_time','first_order_time','last_pay_time'].includes(params.sign)) {
				if(this.tagsTimes) {
					params.min = parseInt(this.tagsTimes[0] / 1000)
					params.max = parseInt(this.tagsTimes[1] / 1000)
				} else {
					params.min = ''
					params.max = ''
				}
			}
			this.$set(this.checkList,this.tagsIndex,params)
			this.dialogVisibleTags = false
		},
		handleDeteleTag(item,index) {
			this.checkList.splice(index,1)
			this.checkTags.splice(this.checkTags.indexOf(item.sign),1)
		},
		handleRemoveGoods(id,index) {
			this.tagsItems.splice(index,1)
			var arr = this.tagsForm.value.split(',')
			for(var i=0;i<arr.length;i++) {
				if(parseInt(arr[i]) === id) {
					arr.splice(i,1)
				}
			}
			this.tagsForm.value = arr.join(',')
		},
		handleShowGoods() {
			this.dialogCheckGoods = []
			this.goodsQuery = {
				goods_cate_id: '',
				goods_name: ''
			}
			this.getGoodsList()
			this.dialogVisibleGoods = true
		},
		handleRemoveCheck(index) {
			this.dialogCheckGoods.splice(index,1)
		},
		handleConfirmGoods() {
			if(!this.dialogCheckGoods.length > 0) {
				this.$message.error('请选择商品!')
				return false
			}
			var checkIds = []
			this.dialogCheckGoods.forEach(item=> {
				checkIds.push(JSON.parse(item).goods_id)
			})
			if(this.tagsForm.value) {
				var currIds = this.tagsForm.value.split(',').map(item => {
					return +item
				})
				checkIds = checkIds.concat(currIds)
			}
			var resultArr = checkIds.filter(function(item,index,self){
				return self.indexOf(item) === index
			})
			this.tagsForm.value = resultArr.join(',')
			this.getGoodsList(this.tagsForm.value)
			this.dialogVisibleGoods = false
		},
		async handleSave() {
			if(this.pageInfo.title === '') {
				this.$message.error(this.$t('seller.errorMsg.crowdName'))
				return false
			}
			if(!this.checkList.length > 0) {
				this.$message.error(this.$t('seller.errorMsg.crowdLabel'))
				return false
			}
			var noValue = []
			this.checkList.forEach(item => {
				if(['last_trade_time','first_order_time','last_pay_time'].includes(item.sign)) {
					if(item.min === '' || item.max === '') {
						noValue.push(item.name)
					}
				} else if(['pay_num','per_ticket_sales','trade_amount','trade_num','repo_cycle','pay_amount'].includes(item.sign)) {
					if(item.min === '' && item.max === '') {
						noValue.push(item.name)
					}
				} else {
					if(item.value === '') {
						noValue.push(item.name)
					}
				}
			})
			if(noValue.length > 0) {
				this.$message.error('['+noValue.join('],[')+']，'+this.$t('seller.errorMsg.crowdAttr'))
				return false
			}
			this.saveLoading = true
			const params = {
				...this.pageInfo
			}
			params.content = JSON.stringify(this.checkList)
			try {
				if(params.crowd_id > 0) {
					await updateCrowd(params)
				} else {
					delete params.crowd_id
					await createCrowd(params)
				}
				this.$message.success(this.$t('seller.successMsg.save'))
				this.$router.push({ name: 'customerDashboard' })
			} catch (error) {
				this.$message.error(this.$t('seller.errorMsg.save'))
				console.log(error)
			}
			this.saveLoading = false
		}
	}
}
</script>

<style lang="scss" scoped>
.seller-card {
	.page-wrap {
		display: flex;
		border-radius: 6px;
		.page-picker {
			flex: 1;
			width: 0;
			padding: 24px;
			background-color: #fafafa;
			.picker-top {
				height: 36px;
				line-height: 36px;
				font-size: 16px;
				margin-bottom: 10px;
			}
			.picker-tips {
				font-size: 14px;
				color: #666666;
				margin-bottom: 24px;
			}
			.picker-editor {
				min-height: 456px;
				padding: 38px 24px 0;
				border: 1px solid #6799ff;
				background-color: #fafcff;
				border-radius: 6px;
				box-sizing: border-box;
				.editor-title {
					font-size: 16px;
					color: #111111;
					font-weight: 500;
				}
				.editor-list {
					display: flex;
					flex-wrap: wrap;
					.item {
						line-height: 16px;
						font-size: 14px;
						display: flex;
						align-items: center;
						border: 1px solid #d5e4ff;
						background-color: #f5f8ff;
						padding: 12px 18px;
						border-radius: 6px;
						margin-top: 18px;
						cursor: pointer;
						.item-tips {
							color: #999;
							margin: 0 4px 0 6px;
						}
						.item-value {
							.el-tag {
								margin-left: 8px;
							}
						}
						.item-icon {
							font-size: 18px;
							color: $--seller-primary;
							margin-left: 12px;
						}
						&.and {
							border: none;
							background: none;
							padding: 0;
							width: 24px;
							justify-content: center;
							align-items: center;
							position: relative;
							.and-icon {
								color: #7fabff;
								background: #d5e4ff;
								width: 18px;
								height: 18px;
								line-height: 18px;
								border-radius: 50%;
								text-align: center;
								position: relative;
							}
							&:before {
								content: "";
								width: 100%;
								position: absolute;
								height: 1px;
								top: 50%;
								background: #d5e4ff;
							}
						}
					}
				}
			}
		}
		.page-tags {
			flex-shrink: 0;
			width: 33%;
			min-width: 500px;
			box-sizing: border-box;
			padding: 32px 52px 0 24px;
			.tags-title {
				color: #333333;
				font-size: 20px;
				line-height: 28px;
			}
			.tags-search {
				display: flex;
				margin-top: 24px;
				li {
					margin-right: 10px;
				}
			}
			.tags-cate {
				margin-top: 24px;
			}
			.tags-list {
				margin-top: 14px;
				.tag-group {
					margin-top: 18px;
					.group-title {
						font-size: 16px;
						color: #333333;
						line-height: 16px;
						span {
							margin-left: 12px;
						}
					}
					.group-search {
						font-size: 14px;
						color: #666;
						line-height: 28px;
						margin-bottom: 32px;
					}
					.group-tags {
						margin-top: 6px;
						display: flex;
						flex-wrap: wrap;
						.tag-item {
							height: 32px;
							border-radius: 3px;
							padding: 0 4px;
							cursor: pointer;
							margin: 12px 0 0 10px;
							position: relative;
							background-color: #ebf0fa;
							.item-name {
								padding: 9px 0;
								line-height: 1;
								font-size: 14px;
							}
							.item-check {
								top: -8px;
								right: -8px;
								color: $--seller-primary;
								font-size: 18px;
								position: absolute;
							}
						}
					}
				}
			}
		}
	}
	.page-bottom {
		margin-top: 24px;
		display: flex;
		justify-content: space-between;
	}
}
::v-deep .seller-dialog {
	.el-dialog__header {
		border-bottom: 1px solid #e5e5e5;
	}
}
.dialog-crowd {
	.crowd-tips {
		font-size: 14px;
		color: #666666;
		line-height: 14px;
	}
	.crowd-wrap {
		margin-top: 24px;
	}
	.crowd-item {
		margin-bottom: 18px;
		.split {
			color: #666;
			margin: 0 8px;
		}
		.unit {
			color: #666;
			margin-left: 8px;
		}
		&:last-child {
			margin-bottom: 0;
		}
	}
	.region-list {
		.checkbox-item {
			width: 156px;
			display: inline-block;
			vertical-align: top;
			margin-bottom: 10px;
			margin-left: 36px;
			[class^=el-icon-] {
				margin-left: 4px;
			}
			&:first-child {
				margin-left: 0;
			}
		}
		.nested-checkbox-group {
			padding: 14px 14px 4px;
			margin-bottom: 10px;
			background: #f7f9ff;
			
			::v-deep .el-checkbox-group {
				.el-checkbox {
					margin-right: 8px;
					margin-bottom: 10px;
					.el-checkbox__label {
						width: 75px;
						white-space: normal;
						vertical-align: top;
					}
				}
			}
		}
	}
	::v-deep .el-input-number {
		width: 140px;
	}
	.crowd-goods {
		display: flex;
		flex-flow: wrap;
		.item {
			flex-shrink: 0;
			display: flex;
			width: 270px;
			height: 80px;
			padding: 12px;
			margin-bottom: 12px;
			box-sizing: border-box;
			background-color: #e8f2ff;
			.item-pic {
				flex-shrink: 0;
				width: 56px;
				height: 56px;
				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}
			.item-main {
				flex: 1;
				width: 0;
				margin-left: 10px;
				.name {
					height: 40px;
					font-size: 12px;
					line-height: 20px;
					text-overflow: ellipsis;
					display: -webkit-box;
					overflow: hidden;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
				}
				.text {
					display: flex;
					line-height: 1;
					justify-content: space-between;
					.price {
						color: #ff7300;
					}
					.action {
						a {
							margin-right: 10px;
						}
					} 
				}
			}
			&:nth-child(2n) {
				margin-left: 20px;
			}
			&.upload-item {
				cursor: pointer;
				color: #3386f1;
				border: 1px dashed #999;
				background-color: transparent;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				[class^=el-icon-] {
					font-size: 18px;
				}
				p {
					margin-top: 5px;
					font-size: 12px;
					line-height: 17px;
				}
			}
		}
	}
}
.dialog-crowd-goods {
	.goods-wrap {
		display: flex;
		height: 400px;
		border: 1px solid #e7e7e7;
		.goods-sidebar {
			flex-shrink: 0;
			width: 140px;
			height: 100%;
			border-right: 1px solid #e7e7e7;
			li {
				span {
					padding: 0 20px;
					height: 40px;
					line-height: 40px;
					display: block;
					cursor: pointer;
					border-left: 3px solid transparent;
					&.active {
						color: #5584ff;
						border-left-color: #5584ff;
						background-color: #e2edff;
					}
					&:hover {
						color: #5584ff;
						background-color: #e2edff;
					}
				}
				ul {
					li {
						span {
							padding: 0 16px 0 28px;
						}
					}
				}
			}
		}
		.goods-content {
			flex: 1;
			width: 0;
			.goods-filters {
				padding: 5px 20px;
				height: 40px;
			}
			.goods-list {
				height: calc(400px - 51px);
				border-top: 1px solid #e7e7e7;
				::v-deep .el-checkbox-group {
					padding-top: 12px;
					.el-checkbox {
						width: 143px;
						height: 210px;
						border: 1px solid #e7e7e7;
						margin-left: 12px;
						margin-bottom: 12px;
						margin-right: 0;
						.el-checkbox__input {
							display: none;
						}
						.el-checkbox__label {
							padding-left: 0;
						}
						&.is-checked {
							.check-goods-pic {
								[class^=el-icon-] {
									display: block;
								}
							}
						}
					}
				}
				.check-goods-pic {
					width: 143px;
					height: 143px;
					position: relative;
					img {
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
					[class^=el-icon-] {
						left: 0;
						top: 0;
						width: 100%;
						height: 100%;
						display: none;
						color: #dadada;
						line-height: 143px;
						text-align: center;
						font-size: 60px;
						position: absolute;
						background-color: rgba(0,0,0,.3);
					}
				}
				.check-goods-main {
					padding: 5px 10px;
					font-size: 12px;
					color: #999;
					line-height: 20px;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}
		}
	}
	.goods-bottom {
		padding-top: 10px;
		.items {
			padding: 0 16px 14px;
			min-height: 100px;
			box-sizing: border-box;
			background: #f5f5f5;
			li {
				display: inline-block;
				width: 72px;
				height: 72px;
				margin-right: 16px;
				margin-top: 16px;
				position: relative;
				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
				[class^=el-icon-] {
					top: -8px;
					right: -8px;
					font-size: 16px;
					position: absolute;
				}
			}
		}
		.tips {
			font-size: 12px;
			margin-top: 15px;
			line-height: 1;
			color: #999;
		}
	}
}
</style>